table.tooltip-rebooking-content {
  margin: var(--eds-space-4);
}

.tooltip-rebooking-content th:after {
  content: '';
  display: block;
  border: 1px solid var(--eds-color-background-hover);
  margin: var(--eds-space-4) auto;
}

.tooltip-rebooking-content tr {
  font-weight: normal !important;
  text-align: left;
}

.tooltip-rebooking-content td {
  padding-top: var(--eds-space-4);
}

.tooltip-rebooking-content td:nth-child(1) {
  padding-right: var(--eds-space-16);
}

.tooltip-rebooking-content td:nth-child(2) {
  text-align: right;
}
