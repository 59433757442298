.layout {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: stretch;
}

.layout > .header {
  flex: 1 0 auto;
}

.layout > .content {
  width: 97.5%;
  margin: auto;
}

.layout > .nike-app-icon {
  width: 56px;
  height: 64px;
}

.layout
  > .header
  > .eds-app-header
  > .eds-menu
  > .eds-menu__body
  > li
  > .eds-menu-item
  > .eds-link--secondary {
  color: white;
}

.count {
  background: linear-gradient(
    to right,
    #ef5350,
    #f48fb1,
    #7e57c2,
    #2196f3,
    #26c6da,
    #43a047,
    #eeff41,
    #f9a825,
    #ff5722
  );
}
