.react-dropdown-tree-select > .dropdown {
  width: 100%;
}

.react-dropdown-tree-select > .dropdown > .dropdown-trigger {
  display: flex;
  padding: 13px 9px;
  border-radius: 4px;
}

.react-dropdown-tree-select > .dropdown > .dropdown-trigger > .tag-list {
  flex-grow: 1;
  display: inline-flex;
  flex-flow: row wrap;
}

/* workaround to not show items */
/* 
.react-dropdown-tree-select > .dropdown > .dropdown-trigger > .tag-list > .tag-item {
  display: none;
}

.react-dropdown-tree-select > .dropdown > .dropdown-trigger > .tag-list > .tag-item:last-child {
  display: flex;
} 
*/

.react-dropdown-tree-select > .dropdown > .dropdown-trigger > .tag-list > .tag-item {
  /* similar to material-ui */
  margin: 3px;
  max-width: calc(100% - 6px);
}

.react-dropdown-tree-select > .dropdown > .dropdown-trigger > .tag-list > .tag-item > .tag {
  display: inline-flex;
  /* similar to material-ui */
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  white-space: nowrap;
  font-size: 0.8125rem;
  align-items: center;
  justify-content: center;
  height: 32px;
  background-color: var(--eds-color-grey-6);
  border-radius: 16px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  vertical-align: middle;
  box-sizing: border-box;
}

.react-dropdown-tree-select
  > .dropdown
  > .dropdown-trigger
  > .tag-list
  > .tag-item
  > .tag
  > .tag-remove {
  /* similar to material-ui */
  /* this is not following EDS but material-ui */
  background-color: rgba(0, 0, 0, 0.26);
  color: var(--eds-color-grey-6);
  width: 22px;
  height: 22px;
  font-size: 16px;
  font-weight: 550;
  vertical-align: middle;
  text-align: center;
  border-radius: 100%;
  margin: 0 5px 0 6px;
}

.react-dropdown-tree-select
  > .dropdown
  > .dropdown-trigger
  > .tag-list
  > .tag-item
  > .tag
  > .tag-remove:hover {
  /* this is not following EDS but material-ui */
  background-color: rgba(0, 0, 0, 0.4);
}

.react-dropdown-tree-select > .dropdown > .dropdown-trigger > .tag-list > .tag-item > .search {
  height: 100%;
  border: none;
}

.react-dropdown-tree-select > .dropdown > .dropdown-trigger.arrow::after {
  margin-left: var(--eds-space-8);
  margin-top: var(--eds-space-4);
}

.react-dropdown-tree-select > .dropdown > .dropdown-content {
  max-height: 450px;
  width: 100%;
  overflow-y: auto;
  font-size: 90%;
}
