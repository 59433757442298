html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  background-color: var(--eds-color-grey-6);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* restyle default date input */
input[type='date'],
.eds-input-box input[type='date'] {
  display: inline-flex;
  width: 180px;
}
