/* Flexbox
// ================================================== */

/* Flex direction */
.inline-flex-row {
  display: inline-flex;
  flex-direction: row;
}

.inline-flex-column {
  display: inline-flex;
  flex-direction: column;
}

.inline-flex-row-reverse {
  display: inline-flex;
  flex-direction: row-reverse;
}

.inline-flex-column-reverse {
  display: inline-flex;
  flex-direction: column-reverse;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

/* Flex wrap */
.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/* Justify content */
.content-center {
  justify-content: center;
}

.content-end {
  justify-content: flex-end;
}

.content-start {
  justify-content: flex-start;
}

.content-space-around {
  justify-content: space-around;
}

.content-space-between {
  justify-content: space-between;
}

.content-space-evenly {
  justify-content: space-evenly;
}

/* Align self */
.justify-self-auto {
  align-self: auto;
}

.justify-self-center {
  align-self: center;
}

.justify-self-baseline {
  align-self: baseline;
}

.justify-self-start {
  align-self: flex-start;
}

.justify-self-end {
  align-self: flex-end;
}

.justify-self-stretch {
  align-self: stretch;
}

/* Align items */
.justify-items-center {
  align-items: center;
}

.justify-items-baseline {
  align-items: baseline;
}

.justify-items-start {
  align-items: flex-start;
}

.justify-items-end {
  align-items: flex-end;
}

.justify-items-stretch {
  align-items: stretch;
}

/* Flex grow */
.flex-grow-0 {
  flex-grow: 0;
}

/* Default */
.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

/* Flex shrink */
.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

/* Default */

/* Flex basis */
.flex-basis-auto {
  flex-basis: auto;
}

/* Default */
.flex-basis-0 {
  flex-basis: 0;
}

.fw-bold {
  font-weight: bold;
}

/* change appearance of scrollbar */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* overwrite eds */
.eds-side-panel {
  max-width: 460px !important;
}

.eds-side-panel__content > div {
  padding-bottom: 0px;
}

.eds-side-panel__header-container {
  margin-bottom: var(--eds-space-32);
}

.eds-checkbox {
  gap: var(--eds-space-4);
}

.eds-side-panel__content > div {
  padding-bottom: 0px !important;
}

#carrierHubs_trigger {
  padding: 5px 9px 5px 9px;
}

.eds-menu > .eds-menu__body > li > .eds-menu-item > .eds-link:hover,
.eds-link--hover {
  text-decoration: none !important;
}
