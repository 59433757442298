.filter-chips {
  display: flex;
  flex-flow: row nowrap;
  gap: var(--eds-space-24);
}

.filter-chips > .filter-chips-action {
  flex: 0 0 auto;
  padding: var(--eds-space-8) var(--eds-space-24) var(--eds-space-8) var(--eds-space-8);
  border-right: 2px solid var(--eds-color-grey-2);
}

.filter-chips > .filter-chips-group {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column wrap;
}

.filter-chips > .filter-chips-group > div > .customChip {
  margin: var(--eds-space-4);
  min-width: 130px;
  border-radius: 0px;
}
