@font-face {
  font-family: 'Nike TG';
  font-style: normal;
  font-weight: 400;
  src: url(../../../fonts/Nike-TG.woff2) format('woff2'),
  url(../../../fonts/Nike-TG.woff) format('woff');
}

@font-face {
  font-family: 'Nike Futura ND';
  font-style: normal;
  font-weight: 400;
  src: url(../../../fonts/Nike-Futura-ot-300.woff2) format('woff2'),
  url(../../../fonts/Nike-Futura-ot-300.woff) format('woff');
}

@font-face {
  font-family: 'Nike Futura';
  font-style: normal;
  font-weight: 400;
  src: url(../../../fonts/Nike-Futura.woff2) format('woff2'),
  url(../../../fonts/Nike-Futura.woff) format('woff');
}

@font-face {
  font-family: 'Helvetica Now Text';
  font-style: normal;
  font-weight: 400;
  src: url(../../../fonts/HelveticaNowText.woff2) format('woff2'),
  url(../../../fonts/HelveticaNowText.woff) format('woff');
}

@font-face {
  font-family: 'Helvetica Now Text Medium';
  font-style: normal;
  font-weight: 400;
  src: url(../../../fonts/HelveticaNowTextMedium.woff2) format('woff2'),
  url(../../../fonts/HelveticaNowTextMedium.woff) format('woff');
}

@font-face {
  font-family: 'Helvetica Now Display Medium';
  font-style: normal;
  font-weight: 400;
  src: url(../../../fonts/HelveticaNowDisplayMedium.woff2) format('woff2'),
  url(../../../fonts/HelveticaNowDisplayMedium.woff) format('woff');
}

/* override EDS fonts (Helvetica Neue unavailable on Windows) */
:root {
  --eds-type-legal-1: normal normal 400 12px/18px 'Helvetica Now Text', Helvetica, Arial, sans-serif;
  --eds-type-body-3: normal normal 400 14px/20px 'Helvetica Now Text', Helvetica, Arial, sans-serif;
  --eds-type-body-2: normal normal 400 16px/24px 'Helvetica Now Text', Helvetica, Arial, sans-serif;
  --eds-type-body-1: normal normal 400 20px/28px 'Helvetica Now Text', Helvetica, Arial, sans-serif;
  --eds-type-subtitle-2: normal normal 500 14px/20px 'Helvetica Now Display Medium', Helvetica,
  Arial, sans-serif;
  --eds-type-subtitle-1: normal normal 500 16px/24px 'Helvetica Now Display Medium', Helvetica,
  Arial, sans-serif;
  --eds-type-title-6: normal normal 500 18px/24px 'Helvetica Now Display Medium', Helvetica, Arial,
  sans-serif;
  --eds-type-title-5: normal normal 500 20px/24px 'Helvetica Now Display Medium', Helvetica, Arial,
  sans-serif;
  --eds-type-title-4: normal normal 500 24px/28px 'Helvetica Now Display Medium', Helvetica, Arial,
  sans-serif;
  --eds-type-title-3: normal normal 500 28px/32px 'Helvetica Now Display Medium', Helvetica, Arial,
  sans-serif;
  --eds-type-title-2: normal normal 500 32px/36px 'Helvetica Now Display Medium', Helvetica, Arial,
  sans-serif;
  --eds-type-title-1: normal normal 500 36px/40px 'Helvetica Now Display Medium', Helvetica, Arial,
  sans-serif;
  --eds-type-display-5: normal normal 700 24px/22px 'Nike Futura', Helvetica, Arial, sans-serif;
  --eds-type-display-4: normal normal 700 40px/34px 'Nike Futura', Helvetica, Arial, sans-serif;
  --eds-type-display-3: normal normal 700 48px/40px 'Nike Futura', Helvetica, Arial, sans-serif;
  --eds-type-display-2: normal normal 700 56px/48px 'Nike Futura', Helvetica, Arial, sans-serif;
  --eds-type-display-1: normal normal 700 72px/62px 'Nike Futura', Helvetica, Arial, sans-serif;
  --eds-type-nike-futura: 'Nike Futura', 'Helvetica Now Text', Helvetica, Arial, sans-serif;
  --eds-type-helvetica-neue: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

body {
  font: var(--eds-type-body-2) !important;
}
