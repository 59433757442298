.eds-modal {
  overflow-y: scroll;
}

.MuiFormHelperText-root {
  margin-left: 0px !important;
  font: var(--eds-type-body-3) !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.reasonField {
  min-width: 25vw;
}

.responsiblePartyField {
  min-width: 30vw;
}

.idpDateCheck {
  min-width: 30vw;
}

.idpWindowCheck {
  min-width: 30vw;
}

.idpShipToCheck {
  min-width: 30vw;
}

.idpShipToAddressCheck {
  min-width: 30vw;
}

.idpTrafficInstructionsCheck {
  min-width: 30vw;
}

@media (min-width: 1200px) {
  .responsiblePartyField {
    min-width: 20vw;
  }

  .idpDateCheck {
    min-width: 20vw;
  }

  .idpWindowCheck {
    min-width: 20vw;
  }

  .idpShipToCheck {
    min-width: 20vw;
  }

  .idpShipToAddressCheck {
    min-width: 20vw;
  }

  .idpTrafficInstructionsCheck {
    min-width: 20vw;
  }
}

.addressTextField {
  margin-bottom: var(--eds-space-8);
}
